
import React, { useContext, useEffect } from 'react';
import { GlobalStyles } from "./GlobalStyles";
import Dashpage from './pages/dashpage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import { Toaster, toast } from 'react-hot-toast';

function App() {



useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    }
  }, []);
  const handleContextMenu = (event) => {
    event.preventDefault();
    toast.error("right click is disabled due to security reasons");
  }

 



  return (
    <>
    <Toaster/>
    <GlobalStyles/>
          <Routes>
    <Route path="/" element={<Dashpage />} />
    <Route path="/login" element={<Login />} />
    </Routes>
    </>
  );
}

export default App; 
