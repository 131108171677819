import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import { brandData } from "../data/global/branddata";
import { menuData, btnData } from "../data/global/menudata";
import Navmenu from "../components/navmenu/navmenu";
import Dashboard from "../components/navitems/dashboard/dashboard";
import Enquiries from "../components/navitems/enquiries/enquiries";
import Messages from "../components/navitems/messages/messages";
import Payments from "../components/navitems/payments/payments";
import Consignments from "../components/navitems/consignments/consignments";
import Invoices from "../components/navitems/invoices/invoices";
import { Navigate, useNavigate } from "react-router-dom";
import { toast,Toaster } from "react-hot-toast";

const Dashpage = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Dashboard");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };


  
 const handleBeforeUnload = () => {
    localStorage.removeItem("isLoggedIn");
  };
  
  const handlePageReload = () => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  const handlePopState = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };
  
  useEffect(() => {
    if (!localStorage.getItem("isLoggedIn")) {
      navigate("/login");
    }
  
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handlePageReload);
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handlePageReload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  
  
 

  

  return (
    <>
    <Toaster/>
      <Header handleOptionChange={handleOptionChange} brandData={brandData} menuData={menuData} btnData={btnData} />
      <Navmenu handleOptionChange={handleOptionChange} />
      {selectedOption === "Dashboard" && <Dashboard />}
      {selectedOption === "Enquiries" && <Enquiries />}
      {selectedOption === "Messages" && <Messages />}
      {selectedOption === "Payments" && <Payments />}
      {selectedOption === "Consignments" && <Consignments />}
      {selectedOption === "invoices" && <Invoices />}
    </>
  );
};

export default Dashpage;
