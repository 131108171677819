import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import "./enquiries.css";
import Button from "../../Button/Button";
import { FaCalendarAlt, FaMapMarkerAlt, FaLongArrowAltRight } from 'react-icons/fa';
import { enquirydeleteroute, enquiryroute } from '../../../api/apiroutes';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { CiLocationArrow1, CiLocationOn, CiCalendarDate } from 'react-icons/ci';
import Loading from "../../../assets/loading.gif";
import empty from "../../../assets/empty.png"



function Enquiries() {
  const [enquiries, setEnquiries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  

  useEffect(() => {
    getAllEnquiries();
  }, []);



  const getAllEnquiries = async () => {
    try {
      const response = await axios.get(enquiryroute);
      setEnquiries(response.data);
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setIsLoading(false);
    }
  };

  const handleEnquiryDelete = async (enquiryId) => {
    if (window.confirm('Are you sure you want to delete this enquiry?')) {
    try {
      await axios.delete(`${enquirydeleteroute}${enquiryId}`);
      getAllEnquiries(); 
    } catch (error) {
      console.error('Error:', error);
    }
  };
}

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };


  const handleRespond = (enquiry) => {
    const subject = encodeURIComponent(`Response to your enquiry on our website for moving from ${enquiry.from} to ${enquiry.to}`);
    const body = encodeURIComponent(`Dear ${enquiry.fullName},
  
  We hope this email finds you well.
  
  Here at Gatividhi Transsolutions Pvt. Ltd., we are delighted to provide you with the answers to your questions. We highly value your inquiry and appreciate the opportunity to assist you.`);
  
    const mailtoLink = `mailto:${enquiry.email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };
  




  return (
    <div className='enquiries-container'>
      {isLoading ? (
        <div className='paymentcontainer'>
          <img src={Loading} alt="Loading..." className="loading" />
          <h4>Loading... enquiry data</h4>
          </div>
      ) : enquiries.length === 0 ? (
        <div className='paymentcontainer'>
      <img src={empty} alt="empty" className="empty" />
      <h4>No enquiries to show</h4>
    </div>
      ) : (
      enquiries.map(enquiry => (
  <div className="card">
      <div className="card-field">
        <BsPerson className='enqicon'/>
        <span className="card-label">Full name:</span>
        <span className="card-value">{enquiry.fullName}</span>
      </div>
      <div className="card-field">
        <MdOutlineMarkEmailRead className='enqicon'/>
        <span className="card-label">Email:</span>
        <span className="card-value">{enquiry.email}</span>
      </div>
      <div className="card-field">
        <CiLocationArrow1 className='enqicon'/>
        <span className="card-label">From:</span>
        <span className="card-value">{enquiry.from}</span>
      </div>
      <div className="card-field">
      <CiLocationOn className='enqicon'/>
      <span className="card-label">To:</span>
        <span className="card-value">{enquiry.to}</span>
      </div>
      <div className="card-field">
        <CiCalendarDate className='enqicon'/>
        <span className="card-label">Date of Moving:</span>
        <span className="card-value">{formatDate(enquiry.date)}</span>
      </div>
      <div className="buttons">
        <div onClick={() => handleEnquiryDelete(enquiry._id)}>
        <Button primary >Delete</Button>
        </div>
        <div onClick={() => handleRespond(enquiry)}>
        <Button blocked  >Respond</Button>
          </div>
        </div>
    </div>
      )
))}
    </div>
  );
}

export default Enquiries;
