import React from 'react'
import "./payments.css"
import empty from "../../../assets/empty.png"

function Payments() {
  return (
    <div className='paymentcontainer'>
      <img src={empty} alt="empty" className="empty" />
      <h4>No Payments to show</h4>
    </div>
  )
}

export default Payments