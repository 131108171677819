import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getallconsignmentsroute, getallinvoicesroute } from '../../../api/apiroutes';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Loading from '../../../assets/loading.gif';
import empty from '../../../assets/empty.png';
import "./invoices.css"
import * as XLSX from 'xlsx';
import invoicetemp from './invoicetemp.docx';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import Button from '../../Button/Button';
import { Link } from 'react-router-dom';

function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllInvoices();
  }, []);




  const getAllInvoices = async () => {
    try {
      const response = await axios.get(getallinvoicesroute);
      const modifiedInvoices = response.data.map((invoice) => ({
        ...invoice,
        id: invoice._id,
      }));
      setInvoices(modifiedInvoices);
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setIsLoading(false);
    }
  };

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

  

  const handleDownloadAgain = (rowData) => {
    loadFile(invoicetemp, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        invoiceNo: rowData.invoiceNo,
        invoiceDate: rowData.invoiceDate,
        vehicleNo: rowData.vehicleNo,
        dateOfSupply: rowData.dateOfSupply,
        consignorName: rowData.consignorName,
        consignorAddress: rowData.consignorAddress,
        consignorGstin: rowData.consignorGstin,
        consignorState: rowData.consignorState,
        consignorStateCode: rowData.consignorStateCode,
        consigneeName: rowData.consigneeName,
        consigneeAddress: rowData.consigneeAddress,
        consigneeGstin: rowData.consigneeGstin,
        consigneeState: rowData.consigneeState,
        consigneeStateCode: rowData.consigneeStateCode,
        grNo: rowData.grNo,
        noOfPackages: rowData.noOfPackages,
        weight: rowData.weight,
        freightCharge: rowData.freightCharge,
        cgst: rowData.cgst,
        upgst: rowData.upgst,
        igst: rowData.igst,
        grandTotal: rowData.grandTotal,
        amountInWords: rowData.amountInWords,
      });
      
      try {
        doc.render();
      } catch (error) {
        throw error;
      }
      const docxFilePath = `invoice_${rowData.invoiceNo}.docx`;
      var out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(out, docxFilePath);
    });

  };

  const columns: GridColDef[] = [
    { field: 'invoiceNo', headerName: 'Invoice No', width: 180 },
    { field: 'invoiceDate', headerName: 'Invoice Date', width: 180 },
    { field: 'consignorName', headerName: 'Consignor Name', width: 180 },
    { field: 'consignorAddress', headerName: 'Consignor Address', width: 180 },
    { field: 'consignorGstin', headerName: 'Consignor GSTIN', width: 180 },
    { field: 'consignorState', headerName: 'Consignor State', width: 180 },
    { field: 'consignorStateCode', headerName: 'Consignor State Code', width: 180 },
    { field: 'consigneeName', headerName: 'Consignee Name', width: 180 },
    { field: 'consigneeAddress', headerName: 'Consignee Address', width: 180 },
    { field: 'consigneeGstin', headerName: 'Consignee GSTIN', width: 180 },
    { field: 'consigneeState', headerName: 'Consignee State', width: 180 },
    { field: 'consigneeStateCode', headerName: 'Consignee State Code', width: 180 },
    { field: 'grNo', headerName: 'GR No', width: 180 },
    { field: 'noOfPackages', headerName: 'No of Packages', width: 180 },
    { field: 'weight', headerName: 'Weight', width: 180 },
    { field: 'vehicleNo', headerName: 'Vehicle No', width: 180 },
    { field: 'dateOfSupply', headerName: 'Date of Supply', width: 180 },
    { field: 'freightCharge', headerName: 'Freight Charge', width: 180 },
    { field: 'cgst', headerName: 'CGST', width: 180 },
    { field: 'upgst', headerName: 'UPGST', width: 180 },
    { field: 'igst', headerName: 'IGST', width: 180 },
    { field: 'grandTotal', headerName: 'Grand Total', width: 180 },
    { field: 'amountInWords', headerName: 'Amount in Words', width: 180 },
    {
      field: 'download',
      headerName: 'Download Invoice again',
      width: 180,
      renderCell: (params) => (
        <div onClick={() => handleDownloadAgain(params.row)}>
        <Button >
          Download Again
        </Button>
        </div>
      ),
    },
  ];  

  console.log(invoices);

  const convertToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(invoices);
    worksheet['!cols'] =
     [{ width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 35 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ]
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'invoices.xlsx');
  };
  
  

  return (
    isLoading ? (
      <div className='paymentcontainer'>
          <img src={Loading} alt="Loading..." className="loading" />
          <h4>Loading... consignment data</h4>
          </div>
    ) : (
      invoices.length === 0 ? (
        <div className='paymentcontainer'>
      <img src={empty} alt="empty" className="empty" />
      <h4>No invoices to show</h4>
    </div>
      ) : (
        <div className="invoicepanel">
          <h4>Centralised Invoice Database</h4>
          <p>All company invoices and invoice data  can be viewed and downloaded to excel here. for any data modification contact admin</p>
          <div className="buttoncontainer">
            <h5>download button to get excel data for all the invoices in database.</h5>
            <div onClick={convertToExcel} >
          <Button >Export Invoices to Excel</Button>
          </div>
          </div>
        <div style={{ height: 500, width: '100%',display:"flex",flexDirection:"column" }}>
          <DataGrid rows={invoices}    columns={columns} />
        </div>
        </div>
      )
    )
  );

}
  
    

export default Invoices;
