import React, { useState } from 'react'
import logo from "../assets/gatividhi.png"
import herotruck from "../assets/herotruck.png"
import "./login.css"
import admin from "../assets/admin.png"
import Button from "../components/Button/Button"
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'

function Login() {
    const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (username === '8221' ||
        username === '8222' ||
        username === '8223' ||
        username === '8224') &&
      password === 'gatividhi@822'
    ) {
      localStorage.setItem('isLoggedIn', true);
      navigate('/');
    } else {
      toast.error('Invalid username or password');
    }
  };

  return (
    <>
    <div className="login-page">
        <Toaster />
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
      </header>
      <div className="login-form">
        <img src={herotruck}  alt={herotruck} className="image" />
        <form onSubmit={handleSubmit}>
            <img src={admin} alt="Logo" className="logo" />
            <h2 >Admin Sign In</h2>
            <p>sign in as admin to access gatividhi's admin panel/control centre</p>
            <input
            type="text"
            placeholder="Username"
            className="input"
            value={username}
            onChange={handleUsernameChange}
          />
          <input
            type="password"
            placeholder="Password"
            className="input"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button type="submit" className="button">Sign In</Button>
        </form>
      </div>
    </div>
    </>
  )
}

export default Login