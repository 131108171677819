

export const menuData = [
  {
    id: 1,
    title: "enquiries",
    navname: "Enquiries", 
  },
  {
    id: 2,
    title: "consigments",
    navname: "Consignments",
  },
  {
    id: 3,
    title: "payments",
    navname: "Payments",
  },
];

export const btnData = {
  title: "mainWebsite",
  link: "https://gatividhits.com/",
};


