import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { messagedeleteroute, messageroute } from '../../../api/apiroutes';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { CiLocationArrow1, CiLocationOn, CiCalendarDate } from 'react-icons/ci';
import Button from '../../Button/Button';
import Loading from "../../../assets/loading.gif";
import empty from "../../../assets/empty.png"

function Messages() {

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllmessages();
  }, []);

  const getAllmessages = async () => {
    try {
      const response = await axios.get(messageroute);
      setMessages(response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

 


  const handleMessageDelete = async (messageId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
    try {
      await axios.delete(`${messagedeleteroute}${messageId}`);
      getAllmessages(); 
    } catch (error) {
      console.error('Error:', error);
    }
  };
}

  const handleRespond = (message) => {
    const recipient = encodeURIComponent(message.fullName);
    const subject = encodeURIComponent('Response to your message on our website');
    const body = encodeURIComponent(`Dear ${message.fullName},

    We hope this email finds you well.
    
    Here at Gatividhi Transsolutions Pvt. Ltd., we are delighted to provide you with answers to your questions. We highly value your message and appreciate the opportunity to assist you.
    
    You messaged us through our website, and the content of your message was as follows: "${message.message}." In response to your message, we would like to inform you that ...`);
  
    const mailtoLink = `mailto:${message.email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };



  return (
    <div className='enquiries-container'>
      {isLoading ? (
        <div className='paymentcontainer'>
        <img src={Loading} alt="Loading..." className="loading" />
        <h4>Loading... message data</h4>
        </div>
      ) : messages.length === 0 ? (
        <div className='paymentcontainer'>
      <img src={empty} alt="empty" className="empty" />
      <h4>No messages to show</h4>
    </div>
      ) : (
    messages.map((message) => (
      <div className="card">
      <div className="card-field">
        <BsPerson className='enqicon'/>
        <span className="card-label">Full name:</span>
        <span className="card-value">{message.fullName}</span>
      </div>
      <div className="card-field">
        <MdOutlineMarkEmailRead className='enqicon'/>
        <span className="card-label">Email:</span>
        <span className="card-value">{message.email}</span>
      </div>
      <div className="card-field">
        <CiLocationArrow1 className='enqicon'/>
        <span className="card-label">message:</span>
        <span className="card-value">{message.message}</span>
      </div>
      <div className="buttons">
      <div onClick={() => handleMessageDelete(message._id)}>
        <Button primary>Delete</Button>
        </div>
        <div onClick={() => handleRespond(message)}>
        <Button blocked  >Respond</Button>
        </div>
        </div>
    </div>
    ))
    )}
  </div>
  )
}

export default Messages