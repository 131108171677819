import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getallconsignmentsroute } from '../../../api/apiroutes';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Loading from '../../../assets/loading.gif';
import empty from '../../../assets/empty.png';

function Consignments() {
  const [consignments, setConsignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllConsignments();
  }, []);

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getAllConsignments = async () => {
    try {
      const response = await axios.get(getallconsignmentsroute);
const consignmentsData = response.data.map((consignment) => ({
  id: consignment._id,
  consignorName: consignment.consignorName,
  consignorAddress: consignment.consignorAddress,
  consignorCity: consignment.consignorCity,
  consignorPincode: consignment.consignorPincode,
  consignorGstin: consignment.consignorGstin,
  consignorPhoneNo: consignment.consignorPhoneNo,
  consigneeName: consignment.consigneeName,
  consigneeAddress: consignment.consigneeAddress,
  consigneeCity: consignment.consigneeCity,
  consigneePincode: consignment.consigneePincode,
  consigneeGstin: consignment.consigneeGstin,
  consigneePhoneNo: consignment.consigneePhoneNo,
  gcNo: consignment.gcNo,
  gcDate: consignment.gcDate,
  from: consignment.from,
  to: consignment.to,
  goodsInvoiceNo: consignment.goodsInvoiceNo,
  goodsInvoiceDate: consignment.goodsInvoiceDate,
  noOfPackages1: consignment.noOfPackages1,
  contents1: consignment.contents1,
  weight1: consignment.weight1,
  noOfPackages2: consignment.noOfPackages2,
  contents2: consignment.contents2,
  weight2: consignment.weight2,
  vehicleNo: consignment.vehicleNo,
  invoiceValue: consignment.invoiceValue,
  eWayBillNo: consignment.eWayBillNo,
  eWayBillExpiryDate: consignment.eWayBillExpiryDate,
}));

      setConsignments(consignmentsData);
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setIsLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'gcNo', headerName: 'GC No', width: 180 },
    { field: 'gcDate', headerName: 'GC Date', width: 180 },
    { field: 'consignorName', headerName: 'Consignor Name', width: 180 },
    { field: 'consignorAddress', headerName: 'Consignor Address', width: 180 },
    { field: 'consignorCity', headerName: 'Consignor City', width: 180 },
    { field: 'consignorPincode', headerName: 'Consignor Pincode', width: 180 },
    { field: 'consignorGstin', headerName: 'Consignor GSTIN', width: 180 },
    { field: 'consignorPhoneNo', headerName: 'Consignor Phone No', width: 180 },
    { field: 'consigneeName', headerName: 'Consignee Name', width: 180 },
    { field: 'consigneeAddress', headerName: 'Consignee Address', width: 180 },
    { field: 'consigneeCity', headerName: 'Consignee City', width: 180 },
    { field: 'consigneePincode', headerName: 'Consignee Pincode', width: 180 },
    { field: 'consigneeGstin', headerName: 'Consignee GSTIN', width: 180 },
    { field: 'consigneePhoneNo', headerName: 'Consignee Phone No', width: 180 },
    { field: 'from', headerName: 'From', width: 180 },
    { field: 'to', headerName: 'To', width: 180 },
    { field: 'goodsInvoiceNo', headerName: 'Goods Invoice No', width: 180 },
    { field: 'goodsInvoiceDate', headerName: 'Goods Invoice Date', width: 180 },
    { field: 'noOfPackages1', headerName: 'No of Packages 1', width: 180 },
    { field: 'contents1', headerName: 'Contents 1', width: 180 },
    { field: 'weight1', headerName: 'Weight 1', width: 180 },
    { field: 'noOfPackages2', headerName: 'No of Packages 2', width: 180 },
    { field: 'contents2', headerName: 'Contents 2', width: 180 },
    { field: 'weight2', headerName: 'Weight 2', width: 180 },
    { field: 'vehicleNo', headerName: 'Vehicle No', width: 180 },
    { field: 'invoiceValue', headerName: 'Invoice Value', width: 180 },
    { field: 'eWayBillNo', headerName: 'eWayBill No', width: 180 },
    { field: 'eWayBillExpiryDate', headerName: 'eWayBill Expiry Date', width: 180 },
  ];
  

  return (
    isLoading ? (
      <div className='paymentcontainer'>
          <img src={Loading} alt="Loading..." className="loading" />
          <h4>Loading... consignment data</h4>
          </div>
    ) : (
      consignments.length === 0 ? (
        <div className='paymentcontainer'>
      <img src={empty} alt="empty" className="empty" />
      <h4>No consignments to show</h4>
    </div>
      ) : (
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid rows={consignments} columns={columns} />
        </div>
      )
    )
  );

}
  
    

export default Consignments;
