
import Box from '@mui/material/Box';
import "./dashboard.css"
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getallconsignmentsroute } from '../../../api/apiroutes';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDrilldown from 'highcharts-drilldown';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Employee ID', width: 150 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'Contact',
    headerName: 'Contact',
    type: 'number',
    width: 160,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'Designation',
    headerName: 'Designation',
    type: 'number',
    width: 160,
    editable: true,
  }
];

const rows = [
  { id: 8221, lastName: 'Singh', firstName: 'Jagmohan', Contact: "+919588159571", Designation: "Logistics Head" },
  { id: 8222, lastName: 'Singh', firstName: 'Durgesh', Contact: "+919936207724", Designation: "Logistics Head" },
  { id: 8223, lastName: 'Singh', firstName: 'Pradeep', Contact: "+919026003602", Designation: "Technical Head" },
  { id: 8224, lastName: 'Singh', firstName: 'Harshit', Contact: "+917310180008", Designation: "Supervisor" },
];


function Dashboard() {

  const [consignments, setConsignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllConsignments();
  }, []);

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getAllConsignments = async () => {
    try {
      const response = await axios.get(getallconsignmentsroute);
      const consignmentsData = response.data.map((consignment) => ({
        id: consignment._id,
        consignmentNo: consignment.consignmentNo,
        consignorName: consignment.consignorName,
        consigneeName: consignment.consigneeName,
        consignmentDate: formatDate(consignment.consignmentDate),
        eWaybillNo: consignment.eWaybillNo,
        invoiceNumber: consignment.invoiceNumber,
        invoiceValue: consignment.invoiceValue,
        amountToBePaid: consignment.amountToBePaid,
      }));
      setConsignments(consignmentsData);
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setIsLoading(false);
    }
  };

  const pastWeekData = consignments.filter((consignment) => {
    const consignmentDate = new Date(consignment.consignmentDate);
    const pastWeek = new Date();
    pastWeek.setDate(pastWeek.getDate() - 7); // Subtract 7 days from the current date
    return consignmentDate >= pastWeek;
  });



  const chartData = {
    xAxis: {
      type: 'category',
      data: pastWeekData.map((consignment) => consignment.consignmentDate),
      name: 'Consignment Date',
    },
    yAxis: {
      type: 'value',
      name: 'Amount To Be Paid',
    },
    series: [
      {
        data: pastWeekData.map((consignment) => consignment.amountToBePaid),
        type: 'bar',
        itemStyle: {
          color: '#0154A0',
        },
      },
    ],
  };
  
 
    const optionsprofit = {
      chart: {
        type: 'column',
        backgroundColor: '#F5F5F5',
      },
      title: {
        text: 'consignment freight charge,transport expenses and profit',
      },
      xAxis: {
        categories: ['GR062301', 'GR062302', 'GR072303', 'GR072304'],
      },
      yAxis: {
        title: {
          text: 'Amount',
        },
      },
      series: [
        {
          name: 'freight charge',
          data: [55000, 31500, 75000, 75000],
        },
        {
          name: 'transport expenses',
          data: [37000, 22000, 65000, 65000],
        },
        {
          name: 'profit',
          data: [18000,9500, 10000, 10000],
        },
      ],
    };


    
  


  return (
    <div className='dashboard-container'>
      <div classname='profitloss'>
      <HighchartsReact highcharts={Highcharts} options={optionsprofit} />
      </div>
      <div className='employeedetails'>
        <h4>Employee Details</h4>
    <Box sx={{ height: 350, width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  </Box>
  </div>
  <div className='chartcontainer'>
  {isLoading ? (
    <div>Loading...</div>)
    : (
      <div className='timeamountchart'>
    </div>
    )
  }
  </div>
  </div>
  )
}

export default Dashboard;